<template>
  <basic-container>
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="$t('datacenter.dataQuality.monitoringTask.title')"
                 v-on="onHeadEvent">
    </head-layout>
    <grid-head-layout ref="searchFrom"
                      :search-columns="searchColumns"
                      v-model="searchForm"
                      v-on="onSearchEvent">
    </grid-head-layout>
    <grid-layout v-bind="bindVal"
                 v-on="onEvent">
      <template slot="state" slot-scope="{row}">
        {{row.state == 1 ? $t('datacenter.dataQuality.monitoringTask.inProgress') : $t('datacenter.dataQuality.monitoringTask.paused')}}
      </template>
      <template #customBtn="{row}">
        <el-button @click="changeStateFn(row)"
                   size="small"
                   type="text">
                   {{row.state == 1 ? $t('datacenter.dataQuality.monitoringTask.pause') : $t('datacenter.dataQuality.monitoringTask.implement')}}
        </el-button>
      </template>
    </grid-layout>
  </basic-container>
</template>

<script>
import { changeState} from "@/api/dataCenter/programManage";
import GridLayout from "@/views/components/layout/grid-layout";

export default window.$crudCommon({
  data () {
    return {
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.addBtn'),
        emit: "head-add",
        type: "button",
        icon: ""
      }],
      gridRowBtn: [
        {
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "grid-edit",
          type: "text",
          icon: ""
        }, {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "grid-romve",
          type: "text",
          icon: ""
        }
      ]
    }
  },
  components:{
    GridLayout
  },
  methods: {
    //新增
    clickHeadAdd() {
      this.$router.push({path:'/datacenter/programManage/edit'})
    },
    //编辑
    clickRowEdit(row) {
      this.$router.push({path:'/datacenter/programManage/edit',query:{id:row.id}})
    },
    //修改状态
    changeStateFn(row) {
      let state = row.state == 1 ? 0 : 1;
      let ts = row.state == 1 ? this.$t('datacenter.dataQuality.monitoringTask.confirmSuspension') : this.$t('datacenter.dataQuality.monitoringTask.confirmExecution');
      this.$confirm(ts, this.$t('tip'), {
        confirmButtonText: this.$t('submitText'),
        cancelButtonText: this.$t('cancelText'),
        type: 'warning'
      }).then(() => {
        changeState({id:row.id,state:state}).then(res => {
          this.getList();
          this.$message.success(this.$t('datacenter.dataQuality.monitoringTask.updateSuccessTip'));
        })
      })
    }
  }
}, {
  //模块路径
  name: 'dataCenter/programManage',
})
</script>

<style>
</style>
